import React, { useState }from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import QuoteRequestFormWrapper from './quoteRequestForm.style';
import Box from 'common/components/Box';
import { ColorRing } from  'react-loader-spinner'


const QuoteRequestForm = () => {
  const Data = useStaticQuery(graphql`
    query {
      foodDeliveryJson {
        QUOTE_REQUEST_FORM {
          title
          list {
            label
            link
          }
        }
      }
    }
  `);
  const { title } = Data.foodDeliveryJson.QUOTE_REQUEST_FORM;
  
  const [customerName, setCustomerName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNbr, setPhoneNbr] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [location, setLocation] = useState(1);
  const [agreedToTermsNCond, setAgreedToTermsNCond] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomerNameChange = (event) => {
    setCustomerName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmailId(event.target.value);
  }

  const handlePhoneNbrChange = (event) => {
    setPhoneNbr(event.target.value);
  }

  const handleOrderNotesChange = (event) => {
    setOrderNotes(event.target.value);
  }

  const handleTermsAndCond = (event) => {
    setAgreedToTermsNCond(!agreedToTermsNCond);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSuccess(false);
    setErrorMessage('');

    console.log('creating quote request..');

    const CREATE_QUOTE_REQUEST_URL = "https://greatnri-svcs-prod.azurewebsites.net/greatnri-svcs/quoteRequest/";

    const response = await fetch(CREATE_QUOTE_REQUEST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customerName, emailId, phoneNbr, orderNotes, location })
    }).then((response) => {
      // Handle the server's response
      if (response.ok) {
        console.log('Quote Creation successful!');
        setSuccess(true);
        setCustomerName('');
        setEmailId('');
        setPhoneNbr('');
        setOrderNotes('');
        setAgreedToTermsNCond(false);
      } else {
        setErrorMessage('Quote creation failed. Please try after sometime');
        console.log('Quote creation failed!');
      }
      setIsLoading(false);
      return response;
    }).catch((error) => {
      console.log(error)
      setIsLoading(false);
      setErrorMessage('Quote creation failed. Please try after sometime');
    });
  };

  return (
    <QuoteRequestFormWrapper id="quoteRequestForm">
      <Container>
        <Heading as="h2" content={title} /> 
        <Box>
        <form onSubmit={handleSubmit} className="quoteRequestForm">
          <input 
            type='text' 
            value={customerName} 
            placeholder='Your name' 
            onChange={handleCustomerNameChange}
            required
          />
          <input 
            type='text' 
            value={emailId} 
            placeholder='Your email' 
            onChange={handleEmailChange}
            required
          />
          <input 
            type='text' 
            id='phoneNbr'
            value={phoneNbr} 
            placeholder='Your phone number' 
            onChange={handlePhoneNbrChange}
            required
          />
          <textarea 
            value={orderNotes} 
            placeholder='Your Message' 
            onChange={handleOrderNotesChange}
            required
          />

          {success && (
            <div className="success">We received your request. Our agent will contact you</div> )
          }

          {errorMessage && 
            (<div className='failure'>{errorMessage}</div>)
          }

          <label>
            <input type="checkbox" checked={agreedToTermsNCond} onChange={handleTermsAndCond}/>
            Agree to terms and conditions
            </label>

          <ColorRing
            visible={isLoading}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
          
        <button
          type="submit"
          disabled={!agreedToTermsNCond || isLoading}
        >
         Submit
        </button>
    </form>
        </Box>
      </Container>
    </QuoteRequestFormWrapper>
  );
};

export default QuoteRequestForm;
