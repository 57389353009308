import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const QuoteRequestFormWrapper = styled.section`
  background-color: #fff2d9;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 100px;
  @media (max-width: 1550px) {
    padding-bottom: 70px;
  }
  @media (max-width: 768px) {
    padding-top: 70px;
  }
  .container {
    max-width: 1430px;

    @media (max-width: 1440px) {
      max-width: 1200px;
    }
    h2 {
      text-align: center;
      margin-bottom: 90px;
      font-weight: bold;
      color: ${themeGet('colors.darkText', '#0F2137')};
      font-size: 26px;
      @media (max-width: 1550px) {
        margin-bottom: 60px;
      }
      @media (max-width: 1199px) {
        font-size: 24px;
      }
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
      @media (max-width: 480px) {
        font-size: 22px;
      }
      @media (max-width: 425px) {
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .quoteRequestForm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 480px;
    margin-top: 20px;
    margin-bottom: 30px;
    align: center;
    margin-left: 370px;


    @media (max-width: 1550px) {
      max-width: 430px;
    }
    @media (max-width: 1199px) {
      max-width: 400px;
    }
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 425px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .mapPin {
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      z-index: 10;
    }

    input[type='checkbox'] {
      margin-right: 5px;
    }

    input[type='text'] {
      width: 100%;
      max-width: 480px;
      background-color: #fff;
      border-radius: 5px;
      border: none;
      outline: none;
      height: 40px;
      border-radius: 5px;
      letter-spacing: -0.3px;
      color:#000000;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
      transition: all 500ms ease;
      &:focus {
        box-shadow: 0 0 0 1px ${themeGet('colors.blackTwo')};
      }
      @media (max-width: 1550px) {
        height: 40px;
        max-width: 430px;
        padding-right: 70px;
      }
      @media (max-width: 1199px) {
        max-width: 400px;
      }
    }
    ::placeholder {
      opacity: 1;
      color: rgba(0, 51, 78, 0.4);
    }
    textarea {
      width: 100%;
      max-width: 480px;
      background-color: #fff;
      border-radius: 5px;
      border: none;
      outline: none;
      height: 230px;
      border-radius: 5px;
      letter-spacing: -0.3px;
      color: #000000;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
      transition: all 500ms ease;
      &:focus {
        box-shadow: 0 0 0 1px ${themeGet('colors.blackTwo')};
      }
      @media (max-width: 1550px) {
        height: 180px;
        max-width: 430px;
        padding-right: 20px;
      }
      @media (max-width: 1199px) {
        max-width: 400px;
      }
    }
    button[type='submit'] {
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      border-radius: 5px;
      background-color: ${themeGet('colors.primary')};
      right: 0;
      opacity: 1;
      color: #fffaf0;
      transition: all 500ms ease;
      padding: 17px 26px;
      font-weight: 700;
      border-radius: 100px;
      i {
        margin-left: 10px;
      }
      cursor: pointer;
      margin-left: 170px; 
      margin-top: 20px;
      transition: opacity 500ms ease;
      &:hover {
        opacity: 0.85;
        background-color: ${themeGet('colors.primaryHover')};
      }
      @media (max-width: 1550px) {
        height: 30px;
        width: 100px;
      }
    }
    button[disabled] {
      background-color: grey;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .buttonWrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  .categoryList {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
  }
  .success {
    margin-bottom: 10px;
    color: green
  }
  .failure {
    margin-bottom: 10px;
    color: red
  }
  .categoryItem {
    display: flex;
    align-items: center;
    color: ${themeGet('colors.darkText', '#0F2137')};
    font-size: 18px;
    font-weight: 500;
    border-radius: 50px;
    margin-left: 10px;
    padding: 13px 26px;
    margin-top: 10px;
    background-color: ${themeGet('colors.grayBackground')};
    transition: all 500ms ease;
    &:hover {
      background-color: ${themeGet('colors.primary')};
      color: #fff;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export default QuoteRequestFormWrapper;
